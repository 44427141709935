<template>
  <StepsStyle v-if="steps !== undefined" v-model:current="currents"
    :direction="direction" :status="status" :size="size"
    @change="$emit('onChangeCurrent', $event)">
    <a-step
      v-for="item in steps"
      :class="item.class && item.class"
      :icon="item.icon && item.icon"
      :status="item.status"
      :key="item.title"
      :title="item.title"
      :description="item.titleDescription"
      :disabled="item.disabled"
    >
      <template #description>
        <div class="wizard-item">
          <h2 v-if="item.subTitle">{{ item.subTitle }}</h2>
          <p v-if="item.description">{{ item.description }}</p>
          <img v-if="item.path" :src="require(`@/static/${item.path}`)" alt="" />
        </div>
      </template>
    </a-step>
  </StepsStyle>

  <div v-if="isVertical" class="steps-wrapper">
    <div
      class="steps-content"
      :style="{ minHeight: height, display: 'flex', justifyContent: 'center', marginTop: 100 }"
    >
      <slot :name="steps[currents].content"></slot>
    </div>
    <ActionWrapper v-if="!isFinished">
      <div class="step-action-wrap">
        <div class="step-action-inner">
          <a-row>
            <a-col :xs="24">
              <div class="steps-action">
                <sdButton v-if="showNavigation && currents > 0" class="btn-prev" type="light" @click="() => prev()">
                  <sdFeatherIcons type="arrow-left" size="16" />
                    {{$t('actions.previous')}}
                </sdButton>

                <sdButton
                  v-if="showNavigation && currents < steps.length - 1"
                  class="btn-next"
                  type="primary"
                  @click="() => next()"
                >
                  {{$t('actions.saveAndNext')}}
                  <sdFeatherIcons type="arrow-right" size="16" />
                </sdButton>

                <sdButton v-if="showNavigation && currents === steps.length - 1" type="primary" @click="onDone">
                  {{$t('actions.done')}}
                </sdButton>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </ActionWrapper>
  </div>
  <template v-else>
    <div
      class="steps-content"
      :style="{ minHeight: height, display: 'flex', justifyContent: 'center', marginTop: 100 }"
    >
      <template v-if="!$_.isEmpty(steps[currents]) && steps[currents].content">
        <slot :name="steps[currents].content"></slot>
      </template>
    </div>
    <ActionWrapper v-if="!isFinished">
      <div class="step-action-wrap">
        <div class="step-action-inner">
          <a-row>
            <a-col :xs="24">
              <div class="steps-action">
                <sdButton v-if="showNavigation && currents > 0" class="btn-prev" type="light" @click="() => prev()">
                  <sdFeatherIcons type="arrow-left" size="16" />
                  <span>{{$t('actions.previous')}}</span>
                </sdButton>
                <sdButton
                  v-if="showNavigation && currents < steps.length - 1"
                  class="btn-next"
                  type="primary"
                  @click="() => next()"
                >
                  <span>{{$t('actions.saveAndNext')}}</span>
                  <sdFeatherIcons type="arrow-right" size="16" />
                </sdButton>
                <sdButton v-if="showNavigation && currents === steps.length - 1" type="primary" @click="onDone">
                  <span>{{$t('actions.done')}}</span>
                </sdButton>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </ActionWrapper>
  </template>
</template>
<script>
import {toRefs} from 'vue';
import VueTypes from 'vue-types';
import {StepsStyle, ActionWrapper} from './style';

const stepStyle = {
  marginBottom: 60,
  boxShadow: '0px -1px 0 0 #e8e8e8 inset'
};

const Steps = {
  name: 'Steps',
  components: {StepsStyle, ActionWrapper},
  props: {
    size: VueTypes.string.def('default'),
    current: VueTypes.number.def(0),
    direction: VueTypes.string.def('horizontal'),
    status: VueTypes.string,
    description: VueTypes.string,
    steps: VueTypes.arrayOf(VueTypes.object),
    wizardItem: VueTypes.arrayOf(VueTypes.object),
    isSwitch: VueTypes.bool.def(false),
    navigation: VueTypes.bool.def(false),
    isFinished: VueTypes.bool.def(false),
    isVertical: VueTypes.bool.def(false),
    height: VueTypes.number.def(150),
    showNavigation: VueTypes.bool.def(false),
    _class: VueTypes.string.def('')
  },
  emits: ['onPrev', 'onNext', 'onDone', 'onChangeCurrent'],
  setup(props, {emit}) {
    const {current} = toRefs(props);
    const next = () => {
      current.value = current.value + 1;
      emit('onNext', current.value);
    };

    const prev = () => {
      current.value = current.value - 1;
      emit('onPrev', current.value);
    };

    const onDone = () => {
      emit('onDone', current.value);
    };

    const onChanges = (curr) => {
      current.value = curr;
      emit('onChange', curr);
    };

    return {
      onChanges,
      prev,
      next,
      currents: current,
      stepStyle,
      onDone
    };
  }
};

export default Steps;
</script>
