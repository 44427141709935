<template>
  <a-col :xs="24">
    <div class="sDash_form-action">
      <a-row class="mt-20">
        <a-col :sm="24" :md="12">
          <a-button class="navigation-button"
                    html-type="button"
                    :disabled="loading"
                    @click="$emit('previousStep')">
            <sd-feather-icons class="mr-10" type="arrow-left" size="16" />
            {{$t(previousLabel)}}
          </a-button>
        </a-col>
        <a-col :sm="24" :md="12">
          <a-button class="navigation-button mr-10 submit-request float-right"
                    type="primary"
                    :loading="loading"
                    :disabled="loading"
                    @click="$emit('nextStep')">
            {{$t(nextLabel)}}
            <sd-feather-icons class="ml-10" v-if="!$_.isEmpty(nextIcon)" :type="nextIcon" size="16" />
          </a-button>
        </a-col>
      </a-row>
    </div>
  </a-col>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'StepsFormNavigation',
  emits: ['nextStep', 'previousStep'],
  props: {
    nextLabel: VueTypes.string.def('actions.next'),
    nextIcon: VueTypes.string.def('arrow-right'),
    previousLabel: VueTypes.string.def('actions.previous'),
    loading: VueTypes.bool.def(false)
  }
});
</script>

<style scoped>
.navigation-button {
  height: 44px !important;
  min-width: 147px !important;
}
.navigation-button>i {
  vertical-align: middle !important;
}
</style>
